'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", "appConfig", function($stateProvider, appConfig) {
        $stateProvider
            .state('index.home', {
                url: '/?contact_us',
                templateUrl: 'app/home/home.html',
                controller: 'HomeCtrl'
            })
            .state('main.home', {
                url: '/comming_soon',
                templateUrl: 'app/tpl/other/comming_soon.html',
                controller: 'HomeCtrl'
            })
            .state('main.aboutUs', {
                url: '/about_us',
                templateUrl: 'app/tpl/other/about_us.html',
                controller: 'MainCtrl'
            });
    }]);